import React from 'react'

const AdvertisingBanner = ({item}) => {
  return (
    <div className='advertising'>
        {item.elements?.map((card, index) =>
            <div key={index} className='advertising-item' style={{backgroundImage: `url(${card.image})`}}>
                <div className='text-wrapper'>
                    <h3>{card.title}</h3>
                    <p>{card.subtitle}</p>
                </div>
            </div>
        )}
    </div>
  )
}

export default AdvertisingBanner