import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import Pagination from '../Pagination';
import ProfessionalServices from '../../services/general/professionalServices';
import FilterProfessional from './FilterProfessional';
import img from "../../images/placeholder.png"

const ProfessionalContainer = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ProfessionalServices.index)

  return (
    <section className="project pt-70 pb-70">
        <div className="container">
            <FilterProfessional setFilterData={setFilterData} params={params}/>
            <div className="row justify-content-center">
                {items?.items?.map((card, index) => 
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                        <NavLink to={`/${lang}/professionals/${card.slug}`}>
                            <div className="team-card">
                                <div className="team-card-img">
                                    <img src={card?.image ? card?.image : img} alt="image"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                </div>
                                <div className="team-card-text">
                                    <h4>{card?.title}</h4>
                                    <div className='position'>{card?.subtitle}</div>
                                    <div className='location'>{card?.location?.title}</div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                )}
            </div>
            <div className='mt-50'>
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        </div>
    </section>
  )
}

export default ProfessionalContainer