import React from 'react'
import WhyWe from '../WhyWe'
import StatisticArea from '../StatisticArea'
import Events from '../Events'
import ProcessWork from '../ProcessWork'
import HotNews from '../HotNews'
import Team from '../Team'
import Services from '../Services'
import Services2 from '../Services2'
import FaqSpoiler from '../FaqSpoiler'
import Partners from '../simple/Partners'
import Description from '../simple/Description'
import Gallery from './Gallery'
import FormContact from '../FormContact'
import img from "../../images/placeholder.png"
import Video from './Video'

const ProfessionalDetailContainer = ({info}) => {
  return (
    <section className="event-details ptb-70">
        <div className="container">
            <div className="professional-detail">
                <div className="image">
                    <img src={info.image ? info.image : img} alt="" 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                </div>
                <div className="professional-content">
                    <h3>{info.title}</h3>
                    <div className='detail-position'>{info.subtitle}</div>
                    <div className='detail-description' dangerouslySetInnerHTML={{__html: info.description}}></div>
                </div>
            </div>
            <div className='professional-grid'>
                {info.extended_description?.map((card, index) =>
                    <div key={index} className='professional-grid-item'>
                        <div>{card.title}</div>
                        <div dangerouslySetInnerHTML={{__html: card.value}}></div>
                    </div>
                )}
            </div>
            <div>
                        {info &&
                            info.elements?.map((elem, index) => 
                                <div key={index}>
                                    {
                                        elem.type === "about" ?
                                        <WhyWe item = {elem}/>
                                        :
                                        elem.type === "advantages" ?
                                        <StatisticArea item = {elem} type={elem.is_background}/>
                                        :
                                        elem.type === "events" ?
                                        <Events item = {elem}/>
                                        :
                                        elem.type === "steps" ?
                                        <ProcessWork item = {elem} type={true}/>
                                        :
                                        elem.type === "articles" ?
                                        <HotNews item = {elem}/>
                                        :
                                        elem.type === "workers" ?
                                        <Team item = {elem} type={elem.is_background}/>
                                        :
                                        elem.type === "services" ?
                                        <Services item = {elem}/>
                                        :
                                        elem.type === "services-2" ?
                                        <Services2 item = {elem}/>
                                        :
                                        elem.type === "faq" ?
                                        <FaqSpoiler item = {elem}/>
                                        :
                                        elem.type === "partners" ?
                                        <Partners item = {elem}/>
                                        :
                                        elem.type === "text-information" ?
                                        <Description item = {elem}/>
                                        :
                                        elem.type === "gallery" ?
                                        <Gallery item = {elem}/>
                                        :
                                        elem.type === "form" ?
                                        <FormContact item={elem}/>
                                        :
                                        elem.type === "video-component" ?
                                        <Video item={elem}/>
                                        :
                                        <div></div>
                                    }
                                </div>
                            )
                        }
                    </div>
        </div>
    </section>
  )
}

export default ProfessionalDetailContainer