import React from 'react'
import useFetchItems from '../hooks/useFetchItems'
import ArticleServices from '../services/general/articleServices'
import Gallery from './complex/Gallery'
import Events from './Events'
import FaqSpoiler from './FaqSpoiler'
import FormContact from './FormContact'
import HotNews from './HotNews'
import ProcessWork from './ProcessWork'
import Services from './Services'
import Services2 from './Services2'
import Description from './simple/Description'
import Partners from './simple/Partners'
import PopularPublication from './simple/PopularPublication'
import ShareBlock from './simple/ShareBlock'
import StatisticArea from './StatisticArea'
import Team from './Team'
import WhyWe from './WhyWe'
import Video from './complex/Video'

const ServiceDetailContainer = ({info}) => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
      } = useFetchItems(ArticleServices.index)
  return (
        <section className="service-details ptb-70"> 
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="details-text-area">
                            <img className="details-main-img" src={info.image} alt="image"/>
                            <h3>{info.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: info.description}}></p>
                        </div>
                        <div>
                            {info &&
                                info.elements?.map((elem, index) => 
                                    <div key={index}>
                                        {
                                            elem.type === "about" ?
                                            <WhyWe item = {elem}/>
                                            :
                                            elem.type === "advantages" ?
                                            <StatisticArea item = {elem} type={elem.is_background}/>
                                            :
                                            elem.type === "events" ?
                                            <Events item = {elem}/>
                                            :
                                            elem.type === "steps" ?
                                            <ProcessWork item = {elem} type={true}/>
                                            :
                                            elem.type === "articles" ?
                                            <HotNews item = {elem}/>
                                            :
                                            elem.type === "workers" ?
                                            <Team item = {elem} type={elem.is_background}/>
                                            :
                                            elem.type === "services" ?
                                            <Services item = {elem}/>
                                            :
                                            elem.type === "services-2" ?
                                            <Services2 item = {elem}/>
                                            :
                                            elem.type === "faq" ?
                                            <FaqSpoiler item = {elem}/>
                                            :
                                            elem.type === "partners" ?
                                            <Partners item = {elem}/>
                                            :
                                            elem.type === "text-information" ?
                                            <Description item = {elem}/>
                                            :
                                            elem.type === "gallery" ?
                                            <Gallery item = {elem}/>
                                            :
                                            elem.type === "form" ?
                                            <FormContact item={elem}/>
                                            :
                                            elem.type === "video-component" ?
                                            <Video item={elem}/>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <ShareBlock/>
                    </div>
                    {items?.items?.length ?
                        <div className="col-lg-4">
                            <div className="sidebar-area pl-20 pt-30">
                                <PopularPublication items={items}/>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
        </section>
  )
}

export default ServiceDetailContainer