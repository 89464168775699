import React from 'react'
import ProjectDetailContainer from '../../components/ProjectDetailContainer'
import PageTop from '../../components/PageTop'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectServices from '../../services/general/projectServices'
import { useLangContext } from '../../i18n/ProvideLang'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ProfessionalDetailContainer from '../../components/complex/ProfessionalDetailContainer'
import ProfessionalServices from '../../services/general/professionalServices'

const ProfessionalDetail = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();
  
    const {info, loading} = useFetchInfo(ProfessionalServices.view, slug, null, lang);
  
    useEffect(() => {
      if(lang){
          if(lang !== lang2) {
              navigate(`/${lang}/professionals/${slug}`)
          }
      }
    }, [lang])

  return (
    <>
      <Helmet>
        <title>{info?.title}</title>
      </Helmet>
      {loading ?
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
      :
      <div>
          <PageTop item={info}/>
          {info && <ProfessionalDetailContainer info={info}/>}
      </div>
      }
    </>
  )
}

export default ProfessionalDetail