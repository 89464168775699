import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {useLangContext} from "../i18n/ProvideLang";

const Header = ({items}) => {
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const {lang, changeLanguage, localizationsItems, languages} = useLangContext();
  const [open, setOpen] = useState();
  const [socials, setSocials] = useState();

  useEffect(() => {
    if(items){
      setEmail(items.find(item => item.type === "email")?.value)
      setAddress(items.find(item => item.type === "address")?.value)
      setSocials(items.find(it => it.type === "socials"))
    }
  }, [items])

  return (
    <section className="topbar plr-100 bg-header-top">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="topbar-left-area">
                    <ul>
                        {email?.map((em, index) => 
                        <li key={index}>
                            <a href={`mailto:${em}`}>
                            <i className="fas fa-envelope"></i> {em}
                            </a>
                        </li>
                        )}
                        <li>
                        <i className="fas fa-map-marker-alt"></i> {address}
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="topbar-right-area">
                    <ul>
                        {socials?.value.map((social, index) => 
                        social.value &&
                            <li key={index}>
                            <NavLink to={social.value} target="_blank">
                                                        <i className={
                                                            social.type === "facebook" ? "fab fa-facebook-f"
                                                            :
                                                            social.type === "twitter" ? "fab fa-twitter"
                                                            :
                                                            social.type === "instagram" ? "fab fa-instagram"
                                                            :
                                                            social.type === "youtube" ? "fab fa-youtube"
                                                            :
                                                            social.type === "telegram" ? "fab fa-telegram"
                                                            :
                                                            social.type === "whatsapp" ? "fab fa-whatsapp"
                                                            :
                                                            "fab fa-facebook-f"
                                                        }>
                                                        </i>
                            </NavLink>
                            </li>
                        )}
                        {languages?.length > 1 &&
                        <li>
                            <div className="language-select option-select-area">
                                <i className="fas fa-globe"></i>
                                <div className={open ? "language-container language-container-open" : "language-container"} onClick={() => setOpen(!open)}>
                                <div className="language-title">
                                    {lang}
                                </div>
                                <div className="language-dropdown">
                                    {languages?.map((lang, index) => 
                                        <div className={"language-item"} key={index} onClick={() => changeLanguage(lang.slug)} >{lang.title}</div>
                                    )}
                                </div>
                                </div>
                            </div>
                        </li>}
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Header;
