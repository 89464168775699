import React from 'react'

const SampleSteps = ({item}) => {
  return (
    <section className="services pt-70 pb-70">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
            <div className="section-content">
                    <div className="row justify-content-center">
                        {item?.elements.map((card, index) =>
                            <div className="col-sm-12 col-xl-6 mb-20" key={index} >
                                <div className='sample-steps-item'>
                                    <div className='title'>{card.title}</div>
                                    <img src={card.image} alt="" />
                                    <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                                </div>
                            </div>
                        )}
                    </div>
            </div>
        </div>
    </section>
  )
}

export default SampleSteps