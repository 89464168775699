import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { useLangContext } from '../i18n/ProvideLang';


const options = [
    { value: 'ru', label: 'Ру' },
    { value: 'kk', label: 'Каз' },
  ]

  const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#D9DBE1' : '#fff',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: state.selectProps.$error ? 'red' : (state.isFocused ? 'var(--mainColor)' : '#D9DBE1'),
        boxShadow: 'none',
        minHeight: '38px',
        borderRadius: '4px',
    }),
    menu: provided => ({...provided, 
        zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0',
        paddingLeft: '.75rem',
    }),
    option: (provided, state) => ({
        ...provided,

        backgroundColor: state.isSelected ? '#f6f6f6' : '#fff',
        color: state.isSelected ? '#00aa55' : '#666666',
        fontWeight: state.isSelected ? '700' : '400',

        '&:hover': {
            backgroundColor: '#f6f6f6',
        }
    }),
    singleValue: (provided, state) => ({ 
        ...provided,
    }),
};

const CustomSelect = ({onChange, options: passedOptions, changeCallback, isDisabled, value: passedValue, ...props}) => {
    const [value, setValue] = useState();
    const [options, setOptions] = useState();
    const {isMulti} = props;
    const {localizationsItems} = useLangContext();

    const handleChange = (data) => {
        if (isMulti) {
            let transformedData = data && data.length ? data.map(item => item.value) : null;
            onChange(transformedData);

            if (changeCallback) {
                changeCallback(transformedData);
            }
        } else {
            onChange(data?.value ? data?.value : null);

            if (changeCallback) {
                changeCallback(data?.value ? data?.value : null);
            }
        }
    }

    useEffect(() => {
        if (passedOptions) {
            const newOptions = passedOptions.map(item => {
                return {label: item.label, value: item.value}
            });
            setOptions(newOptions);
        }

        if (passedValue) {
            if (isMulti) {
                setValue(passedOptions ? passedOptions.filter(item => passedValue.includes(item.value)) : '');
            } else {
                setValue(passedOptions ? passedOptions.find(item => item.value === passedValue) : '')
            }
        } else {
            setValue(null)
        }
    }, [passedValue, isMulti, passedOptions]);

  return (
    <Select isClearable options={options} value={value ? value : ''}
    placeholder={props.placeholder ? props.placeholder : localizationsItems?.select} isDisabled={isDisabled}
    onChange={handleChange} {...props} noOptionsMessage={() => localizationsItems?.no_options}
    loadingMessage={() => localizationsItems?.loading} styles={styles}/>
  )
}

export default CustomSelect