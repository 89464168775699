import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Menu from '../components/Menu';
import useFetchItems from '../hooks/useFetchItems';
import AppServices from '../services/general/appServices';
import { ERROR_PAGE_ROUTE, MAIN_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';

const AppRouter = () => {
  const [items, setItems] = useState();

  const fetchdata = async () => {
    const res = await AppServices.settings();

    if(res.statusCode === 200){
      setItems(res.content)
    }
  }
  useEffect(() => {
    fetchdata();
  }, [])

  useEffect(() => {
    if(items){
      let color = items.find(it => it.type === "color-primary")?.value;
      let backColor = items.find(it => it.type === "back-color")?.value;
      let menuColor = items.find(it => it.type === "menu_back_color")?.value;
      let headerTopColor = items.find(it => it.type === "header_top_color")?.value;
      let footerColor = items.find(it => it.type === "footer_color")?.value;
      let menuFontColor = items.find(it => it.type === "menu-font-color")?.value;
      let headerFontColor = items.find(it => it.type === "header-font-color")?.value;
      let headerIconColor = items.find(it => it.type === "header-icon-color")?.value;
      let footerIconColor = items.find(it => it.type === "footer-icon-color")?.value;
      let mainColorHover = items.find(it => it.type === "main-color-hover")?.value;
      let mainBannerColor = items.find(it => it.type === "main-banner-color")?.value;
      let linkColorTop = items.find(it => it.type === "link-color-top")?.value;
      let mainFont = items.find(it => it.type === "font-primary")?.value;
      let secondatyFont = items.find(it => it.type === "font-secondary")?.value;
      let buttonColor = items.find(it => it.type === "button-color")?.value;
      let hotButtonColor = items.find(it => it.type === "hot-button-color")?.value;

      let head = items.find(it => it.type === "head")?.value;
      let bottom = items.find(it => it.type === "bottom")?.value;
      let header = items.find(it => it.type === "header")?.value;

      if(head){
        head.forEach(element => {
            const divFragment =  document.createRange().createContextualFragment(element)
            document.head.append(divFragment);
        });
      }

      if(header){
        header.forEach(element => {
          const divFragment =  document.createRange().createContextualFragment(element)
          document.body.append(divFragment);
        });
      }

      if(bottom){
        bottom.forEach(element => {
          const divFragment =  document.createRange().createContextualFragment(element)
          document.body.prepend(divFragment);
        });
      }

      color && document.documentElement.style.setProperty('--mainColor', color)
      backColor && document.documentElement.style.setProperty('--back-color', backColor)
      menuColor && document.documentElement.style.setProperty('--menu-color', menuColor)
      headerTopColor && document.documentElement.style.setProperty('--header-color', headerTopColor)
      footerColor && document.documentElement.style.setProperty('--footer-color', footerColor)
      menuFontColor && document.documentElement.style.setProperty('--menuFontColor', menuFontColor)
      headerFontColor && document.documentElement.style.setProperty('--headerFontColor', headerFontColor)
      headerIconColor && document.documentElement.style.setProperty('--headerIconColor', headerIconColor)
      footerIconColor && document.documentElement.style.setProperty('--footerIconColor', footerIconColor)
      mainColorHover && document.documentElement.style.setProperty('--mainColorHover', mainColorHover)
      mainBannerColor && document.documentElement.style.setProperty('--mainBannerColor', mainBannerColor)
      linkColorTop && document.documentElement.style.setProperty('--linkColorTop', linkColorTop)
      buttonColor && document.documentElement.style.setProperty('--buttonColor', buttonColor)
      hotButtonColor && document.documentElement.style.setProperty('--hotButtonColor', hotButtonColor)
      
      if(mainFont){
        let elements = mainFont.elements
        for(let i =0; i<elements.length; i++){
          const font_name = new FontFace(mainFont.font_name, `url(${elements[i].link})`, {
            style: elements[i].type,
            weight: elements[i].value
          });
          document.fonts.add(font_name);
        }
        document.documentElement.style.setProperty('--fontFamily', `${mainFont.font_name}, sans-serif`)
      }
      if(secondatyFont){
        let elements = secondatyFont.elements
        for(let i =0; i<elements.length; i++){
          const font_name = new FontFace(secondatyFont.font_name, `url(${elements[i].link})`, {
            style: elements[i].type,
            weight: elements[i].value
          });
          document.fonts.add(font_name);
        }
        document.documentElement.style.setProperty('--fontFamily2', `${secondatyFont.font_name}, sans-serif`)
      }
    }
  }, [items])

  return (
    <Routes>
        {publicRoutes.map(({path, Component}) => 
          <Route key={path} path={path} element={
            <>
              <Header items={items}/>
              <Menu item={items}/>
              <Component></Component>
              <Footer item={items}/>
            </>
          } exact/>
        )}
        <Route
            path="*"
            exact
            element={<Navigate to={ERROR_PAGE_ROUTE}/>}
        />

    </Routes>
  )
}

export default AppRouter