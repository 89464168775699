import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomSelect from '../CustomSelect';
import Utils from '../../services/utils';
import ProfessionalServices from '../../services/general/professionalServices';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useLangContext } from '../../i18n/ProvideLang';

const FilterProfessional = ({setFilterData, params}) => {
    const {localizationsItems} = useLangContext();

    const {handleSubmit, control, setValue, watch, register, reset} = useForm();

    const countryId = watch('country_id', null)
    const cityId = watch('city_id', null);
    const typeId = watch('type_id', null);

    const [countries, setCountries] = useState();
    const [countriesLoading, setCountriesLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [types, setTypes] = useState();
    const [typesLoading, setTypeLoading] = useState(true);

    const fetchCountries = useCallback(async () => {
        setCountriesLoading(true);

        const res = await ProfessionalServices.countries();

        if (res.content) {
            setCountries(res.content);
        } else {
            console.log(res.message)
        }

        setCountriesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        setCitiesLoading(true);

        const res = countryId ? await ProfessionalServices.cities({queryString: `country_id=${countryId}`}) : await ProfessionalServices.cities();

        if (res.content) {
            setCities(res.content);

            if (!res.content.some(item => item.value === cityId)) {
                setValue('city_id', null)
            }
        } else {
            console.log(res.message)
        }

        setCitiesLoading(false);
        
    }, [setValue, countryId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    const fetchTypes = useCallback(async () => {
        setTypeLoading(true);

        const res = await ProfessionalServices.types();

        if (res.content) {
            setTypes(res.content);
        } else {
            console.log(res.message)
        }

        setTypeLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchTypes();
    }, [fetchTypes]);

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filter-professionals">
            <div>
                <input {...register('term')} type="text" defaultValue={params.get('term')} className="form-control" placeholder={localizationsItems?.filter_name}/>
            </div>
            <div>
                <Controller name="country_id" control={control}
                    render={({field: {onChange, value}}) => (
                        <CustomSelect $small options={countries} onChange={onChange}
                                    value={value}
                                    isLoading={countriesLoading}
                                    isDisabled={countriesLoading}
                                    placeholder={localizationsItems?.filter_country}

                        />
                    )}
                />
            </div>
            <div>
                <Controller name="city_id" control={control}
                    render={({field: {onChange, value}}) => (
                        <CustomSelect $small options={cities} onChange={onChange}
                                    value={value}
                                    isLoading={citiesLoading}
                                    isDisabled={citiesLoading}
                                    placeholder={localizationsItems?.filter_city}

                        />
                    )}
                />
            </div>
            <div>
                <Controller name="type_id" control={control}
                    render={({field: {onChange, value}}) => (
                        <CustomSelect $small options={types} onChange={onChange}
                                    value={value}
                                    isLoading={typesLoading}
                                    isDisabled={typesLoading}
                                    placeholder={localizationsItems?.filter_type}

                        />
                    )}
                />
            </div>
            <div className='last'>
                <button className='default-button btn-sm'>{localizationsItems?.find_professionals}</button>
            </div>
        </div>
    </form>
  )
}

export default FilterProfessional