import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import BlogGrid from '../../components/BlogGrid';
import Gallery from '../../components/complex/Gallery';
import Events from '../../components/Events';
import FaqSpoiler from '../../components/FaqSpoiler';
import FormContact from '../../components/FormContact';
import PageTop from '../../components/PageTop';
import ProcessWork from '../../components/ProcessWork';
import Services from '../../components/Services';
import Services2 from '../../components/Services2';
import Description from '../../components/simple/Description';
import Partners from '../../components/simple/Partners';
import StatisticArea from '../../components/StatisticArea';
import Team from '../../components/Team';
import WhyWe from '../../components/WhyWe';
import useFetchItems from '../../hooks/useFetchItems';
import { useLangContext } from '../../i18n/ProvideLang';
import AppServices from '../../services/general/appServices';
import { Helmet } from 'react-helmet';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import Video from '../../components/complex/Video';
import SampleSteps from '../../components/complex/SampleSteps';

const SamplePage = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

    useEffect(() => {
      if(lang){
        if(lang !== lang2){
            navigate(`/${lang}/page/${slug}`)
        }
      }
    }, [lang])

  return (
    <>
      <Helmet>
        <title>{items?.title}</title>
      </Helmet>
      {loading ? 
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
      :
        <div>
          {items && 
          <>
            <PageTop item={items}/>
            {items?.description &&
              <div className="container">
                  <div className='row ptb-100'>
                      <div className='row-lg-8'>
                          <div dangerouslySetInnerHTML={{ __html: items.description}}></div>
                      </div>
                  </div>
              </div>
            }
          {items.elements?.map((elem, index) => 
            <div key={index}>
                {elem.type === "about" ?
                <WhyWe item = {elem}/>
                :
                elem.type === "advantages" ?
                <StatisticArea item = {elem} type={elem.is_background}/>
                :
                elem.type === "events" ?
                <Events item = {elem}/>
                :
                elem.type === "steps-1" ?
                <ProcessWork item = {elem}/>
                :
                elem.type === "steps" ?
                    elem.is_component &&
                        <SampleSteps item = {elem}/>
                :
                elem.type === "articles" ?
                <BlogGrid/>
                :
                elem.type === "workers" ?
                <Team item = {elem} type={elem.is_background}/>
                :
                elem.type === "services" ?
                <Services item = {elem}/>
                :
                elem.type === "services-2" ?
                <Services2 item = {elem}/>
                :
                elem.type === "faq" ?
                <FaqSpoiler item = {elem}/>
                :
                elem.type === "partners" ?
                <Partners item = {elem}/>
                :
                elem.type === "text-information" ?
                <Description item = {elem}/>
                :
                elem.type === "gallery" ?
                <Gallery item = {elem}/>
                :
                elem.type === "form" ?
                <FormContact item={elem}/>
                :
                elem.type === "advertising-banner-2" ?
                <AdvertisingBanner item={elem}/>
                :
                elem.type === "video-component" ?
                <Video item={elem}/>
                :
                <div></div>
                }
            </div>
          )}
          </>}
        </div>
      }
    </>
  )
}

export default SamplePage