import React, { useEffect, useState } from 'react'
import Fancybox from './FancyBox';

const Video = ({item}) => {
  return (
    <section className="we-are pb-70 pt-70">
        <div className="container">
        <Fancybox
            options={{
                Carousel: {
                infinite: false,
                },
            }}
        >
            <a href={item.video} data-fancybox="gallery">
                <div className='video'>
                    <div className="main-container">
                        <div className='video-back'>
                            {item.video_image &&
                                <img src={item.video_image} alt="" />
                            }
                        </div>
                        <div className='video-content'>
                            <div className='center'>
                                <i className='fa fa-play'></i>
                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_23">{item.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Fancybox>
        </div>
    </section>
  )
}

export default Video