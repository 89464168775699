import http from "./http-common"
import {getData} from "../handlers";

export default class ProfessionalServices {
    static index = (queryString) => {
        return getData(http, `/app/professionals${queryString ? `?${queryString}` : ""}`);
    }

    static categories = (queryString) => {
        return getData(http, `/app/professionals/categories${queryString ? `&${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/professionals/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static countries = async (data) => {
        const res = await getData(http,`/app/locations/countries${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res.content)
    }

    static cities = async (data) => {
        const res = await getData(http,`/app/locations/cities${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res.content)
    }

    static types = async (data) => {
        const res = await getData(http,`/app/professionals/types${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res.content)
    }

    static transformToOptions = ({items, ...props}) => {
        const newContent = items.map((item) => {
            const {title, id, ...rest} = item;

            return {
                label: title,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }
}