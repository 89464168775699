export const PROJECTS_ROUTE = '/projects'
export const BLOG_ROUTE = '/articles'
export const SERVICES_ROUTE = '/services'
export const EVENTS_ROUTE = '/events'


export const MAIN_PAGE_ROUTE = '/:lang'
export const PROJECTS_PAGE_ROUTE = '/:lang/projects'
export const PROJECTS_DETAIL_PAGE_ROUTE = '/:lang/projects/:slug'
export const SERVICES_PAGE_ROUTE = '/:lang/services'
export const SERVICES_DETAIL_PAGE_ROUTE = '/:lang/services/:slug'

export const SAMPLE_PAGE_SOUTE = '/:lang/page/:slug'


export const BLOG_PAGE_ROUTE = '/:lang/articles'
export const BLOG_DETAIL_PAGE_ROUTE = '/:lang/articles/:slug'


export const PROFESSIONAL_PAGE_ROUTE = '/:lang/professionals'
export const PROFESSIONAL_DETAIL_PAGE_ROUTE = '/:lang/professionals/:slug'


export const ABOUT_PAGE_ROUTE = '/:lang/about'

export const BLOG_RIGHT_SIDEVAR = "/blog/right-sidebar"
export const FAQ_PAGE_ROUTE = '/faq'


export const CONTACTS_PAGE_ROUTE = '/contacts'
export const EVENTS_PAGE_ROUTE = '/:lang/events'
export const ERROR_PAGE_ROUTE = '/:lang/error'
export const EVENT_DETAIL_PAGE_ROUTE = '/:lang/events/:slug'
