import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../i18n/ProvideLang';
import { MAIN_PAGE_ROUTE } from '../navigation/Constants'

const PageTop = ({item}) => {
    const {localizationsItems} = useLangContext();

  return (
        <section className={`uni-banner uni-banner-${item?.id}`} style={{backgroundImage: item.detail_image && `url(${item.detail_image})`}}> 
            <div className="container">
                <div className="uni-banner-text-area">
                    <h1 dangerouslySetInnerHTML={{__html: item?.title}}></h1>
                    <ul>
                        <li><NavLink to={MAIN_PAGE_ROUTE}>{localizationsItems?.main_page}</NavLink></li>
                        <li dangerouslySetInnerHTML={{__html: item?.title}}></li>
                    </ul>
                </div>
            </div>
        </section>
  )
}

export default PageTop